// Apply styling to elements that apply within the builder
#rs-builder {

  // HEROS
  .block.hero-slides {
    z-index: 0;
    height: max-content;
    flex-direction: column;
    .slideshow {
      position: relative;
      z-index: 1;
      .swiper-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
      }
      img {
        height: 150px;
        width: auto;
      }
    }
    .content {
      padding: 64px 10px 128px;
    }
  }
  .block.hero-video {
    z-index: 0;
    &::after {
      pointer-events: none;
      z-index: -1;
    }
    .video.background {
      // z-index: 2;
      a {
        z-index: 1;
        margin: 10px 0 0 10px;
        .button;
        background-color: #666666;
        min-width: unset;
        width: fit-content;
        color: @heading-alt;
      }
    }
    .hidden {
      display: block !important;
    }
    iframe {
      // display: none;
      pointer-events: none;
    }
    .inner {
      pointer-events: none;
    }
    .content {
      padding: 64px 10px 128px;
      pointer-events: all;
    }
  }
  // SPECIALS
  .block.specials-flag {
    position: relative;
    height: max-content;
    .flag {
      top: 40px;
      right: 0;
      transform: rotate(0);
    }

    .specials-overlay {
      visibility: visible;
      position: relative;
      z-index: 0;
      opacity: 1;
      height: max-content;
      padding: 20px;
    }
    .specials-close-btn {
      pointer-events: none;
    }
  }
  // CARDS
  .block.cards {
    .card {
      a {
        height: 50%;
        bottom: 0;
        top: unset;
        z-index: 1;
        background-image: url("https://placehold.co/400x200/858585/494949?text=Link+Click+Here");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.3;
      }
      .card-content {
        z-index: 2;
        .card-title {
          transform: translateY(0);
        }
        .card-arrow {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      &:hover, &:focus-within {
        .card-inner {
          .card-bg {
            img {
              transform: scale(1.02);
            }
          }
          .border {
            height: 4px;
            opacity: 0;
          }
        }
      }
    }
  }
  // TESTIMONIALS
  .block.testimonials {
    .testimonial-slides {
      display: block;
    }
    .arrow {
      display: none;
    }
    .swiper-slide {
      border: 1px solid white;
      margin-bottom: 5px;
      padding: 5px;
    }
    .text {
      pointer-events: all;
    }
  }
  // SUBPAGE HERO
  .block.subpage-hero {
    padding: 0 10px;
  }
}