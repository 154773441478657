.block.events {
  padding: 52px 20px;
  background-repeat: repeat;
  background-color: @primary-bg;
  position: relative;

  a {
    color: @text-alt;
    text-decoration: underline;
    &:hover, &:focus {
      color: @heading;
    }
  }
  .bg-colors {
    background: linear-gradient(to bottom, transparent 300px, @page-bg 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .event-card {
    background-color: @primary-bg;
    width: calc(100% - 24px);
    margin: 12px;
    .event-image {
      height: 300px;
      overflow: hidden;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .body {
      animation-delay: 0.6s;
      .title {
        h2, h3, h4, h5 {
          font-size: 2.5rem;
        }
      }
      padding: 16px;
    }
    &:hover, &:focus, &:focus-within {
      background-color: @secondary-bg;
    }

  }

  .tablet({
    .events-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      .event-card {
        flex: 0 0 calc(33.333% - 24px);
      }
    }
  });
}