.block.resident-services-forms {
  form {
    padding: 12px;
    label {
      font-size: 1.6rem;
    }

    input, text-area, select {
      border: none;
    }
  }
}