.block.topbar {
  position: absolute;
  top: 25px;
  width: 100%;
  z-index: 10;

  .inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 10px;
    z-index: 1;
  }

  .logo {
    font-size: 2rem;
    text-align: center;
    a {
      color: @heading-alt;
    }
    .name {
      border-bottom: 1px solid @heading-alt;

      font-family: @title;
      font-weight: 700;
    }
    .tagline {
      font-family: "Satisfy", cursive;
      font-weight: 400;
    }
    & a:hover, & a:focus {
      opacity: 0.8;
    }
  }

  .mobile-menu button{
    vertical-align: top;
    border-radius: 0;
    padding: 3px 8px 8px;
    min-width: unset;
    height: 48px;
    .line {
      width: 35px;
      height: 1.2px;
      background-color: @button-text;
      margin-top: 5px;
    }
  }

  .mobile-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    min-height: 150vh;
    min-height: 150dvh;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    &[open] {
      visibility: visible;
      opacity: 1;
    }
    .backdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color:  @primary-bg;
      z-index: -1;
    }
    .wrapper {
      width: 100%;
      height: 100vh;
      height: 100dvh;
      padding: 10px 10px 55px;
      min-height: 100vh;
      overflow: auto;
      position: relative;
      overflow-y: auto;
    }
    .top {
      display: flex;
      justify-content: space-between;

      .logo {
        font-size: 2.8rem;
        text-align: center;
        min-width: 200px;
        margin-top: 10px;
        a {
          color: @heading-alt;
        }
        .name, .tagline {
          font-size: 1.6rem;
        }
      }
      .modal-close-btn {
        font-size: 2.0rem;
        color: @heading-alt;
        cursor: pointer;
        width: max-content;
        height: max-content;
        min-width: unset;
        border-radius: 0;
        background-color: transparent;
      }
    }
  }

  .mobile-overlay .mobile-list {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .navbar ul, .mobile-overlay ul {
    display: flex;
    flex-direction: column;
    font-family: @title;
    font-weight: 700;
    .dropdown {
      background-color: @text-link;
    }
    .dropdown-item {
      position: relative;
      display: flex;
      flex-direction: column;
      .dropdown-btn {
        padding: 8px 3.2rem 8px 12px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        min-width: unset;
        width: 100%;
        text-align: left;
        transition: unset;
        &::after {
          content: '';
          display: block;
          width: 1rem;
          height: 1rem;
          position: absolute;
          right: 12px;
          top: 1.3rem;
          // Down arrow
          border: 3px solid @text-alt;
          border-top: none;
          border-right: none;
          border-radius: 2px;
          transform: rotate(-45deg);
        }
        &:focus, &:hover {
          color: @text-link;
          &::after {
            border: 3px solid @text-link;
            border-top: none;
            border-right: none;
          }
        }
      }
    }

    li a {
      position: relative;
      padding: 8px 12px;
      color: @text-alt;
      min-width: unset;
      width: 100%;
      &:hover, &:focus {
        color: @text-link;
      }
    }
    .dropdown li:focus-within, .dropdown li:hover {
        background-color: @heading-alt;
    }
  }

  .tablet({
    .logo {
        font-size: 2.8rem;
    }
  });

  .vp(1000px, {
    .navbar ul, .mobile-overlay ul {
      .dropdown-item {
        position: relative;
        .dropdown {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
        }
        .dropdown-btn {
          padding: 8px 3.2rem 8px 12px;
          background-color: transparent;
          border: none;
          border-radius: 0;
          min-width: unset;
          width: max-content;
          font-size: 1.6rem;
          &::after {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 12px;
            top: 1.6rem;
            // Down arrow
            border: 3px solid @text-alt;
            border-top: none;
            border-right: none;
            border-radius: 2px;
            transform: rotate(-45deg);
          }
          &:focus, &:hover {
            color: @text-alt;
            &::after {
              border: 3px solid @text-alt;
              border-top: none;
              border-right: none;
            }
          }
        }
        &.expanded {
          .dropdown-btn {
            color: @text-link-hover;
            width: max-content;
            &::after {
              border: 3px solid @text-link-hover;
              border-top: none;
              border-right: none;
            }
          }
          .dropdown {
            display: block;
            padding: 8px 0;
            top: 100%;
            left: 0;
            width: 100%;
            z-index: 1;
            border-radius: 0 0 5px 5px;
          }
        }
      }

      li a:not(.dropdown-link) {
        // width: max-content;
      }
      .dropdown-link {
        width: max-content;
      }
    }
  });

  .desktop({
    .logo {
      max-width: 365px;
    }
    .mobile-overlay {
      display: none;
    }
    .navbar .main-nav {
      flex-direction: row;
      align-items: center;
      .dropdown-item.expanded {
        background-color: @text-link;
        .dropdown-btn {
          color: @heading-alt;
          &::after {
            border: 3px solid @heading-alt;
            border-top: none;
            border-right: none;
          }
        }
        .dropdown {
          position: absolute;
          background-color: @page-bg;
          width: max-content;
          .dropdown-link {
            color: @primary-bg;
            width: 100%;
            &:hover, &:focus {
              background-color: #f5f5f5;
            }
          }
          &.expanded {
            .dropdown-btn {
              color: @text-link-hover;
              width: max-content;
            }
            .dropdown {
              display: block;
              padding: 8px 0;
              top: 100%;
              left: 0;
              width: 100%;
              z-index: 1;
              border-radius: 0 0 5px 5px;
            }
          }
        }
      }
      li a:not(.dropdown-link) {
        &:hover, &:focus {
          color: @heading-alt;
          background-color: @text-link;
        }
      }
    }
  });
}