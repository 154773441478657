// standard colors
@text:					var(--text-color);
@text-alt:				var(--text-color-alt);
@text-link:				var(--text-link-color);
@text-link-hover:		var(--text-link-color-hover);
@heading:				var(--heading-color);
@heading-alt:			var(--heading-color-alt);
@heading-link:			var(--heading-link-color);
@button-bg:				var(--button-bg-color);
@button-border:			var(--button-border-color);
@button-text:			var(--button-text-color);
@button-bg-hover:		var(--button-bg-color-hover);
@button-text-hover:		var(--button-text-color-hover);
@primary-bg:			var(--primary-bg-color);
@secondary-bg:			var(--secondary-bg-color);
@page-bg:				var(--page-bg-color);


// breakpoints
@phablet: 		480px;
@tablet: 		768px;
@desktop: 		1400px;
@ultra: 		1500px;
@maxInner: 		1344px;

// font settings
@main: 			var(--text-font);;
@title: 		var(--heading-font);;

// misc
@maxwidth: 1200px;
