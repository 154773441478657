.block.cards {
  background-color: @primary-bg;
  background-repeat: repeat;
  padding: 64px 32px;
  position: relative;
  min-height: 600px;

  h2, h3, h4, h5 {
    font-size: 2.5rem;
  }

  .bg-colors {
    background: linear-gradient(to bottom, transparent 300px, @primary-bg 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .card {
    height: 320px;
    width: 100%;
    padding: 10px 12px;
    .card-inner {
      position: relative;
      height: 100%;
      width: 100%;
      .card-bg {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        position: relative;
        z-index: 0;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          pointer-events: none;
          z-index: 1;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.04);
          transform-origin: center;
          transition: transform 0.5s ease;
        }
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        padding: 0 0 18px 25px;
        transition: all 0.5s ease;
        z-index: 2;
      }
      .border {
        position: absolute;
        bottom: 0;
        height: 0;
        width: 100%;
        opacity: 0;
        background-color: @secondary-bg;
        transition: all 0.5s ease;
      }
      .card-content {
        position: absolute;
        left: 25px;
        bottom: 15px;
      }
    }

    &:hover, &:focus-within {
      .card-inner {
        .card-bg {
          img {
            transform: scale(1.02);
          }
        }
        .card-content {
          .card-title {
            transform: translateY(0);
          }
          .card-arrow {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
        .border {
          height: 4px;
          opacity: 1;
        }
      }
    }
  }


  .tablet({
    .cards-container {
      display: flex;
      flex-direction: row;
    }
      .card {
        width: 33.3333%;
        height: 500px;
        .card-content {
          .card-title {
            transform: translateY(8px);
            transition: all 0.5s ease;
          }
          .card-arrow {
            transform: translateY(18px);
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease;
          }
        }
      }
  });
}