html {
	font-size: 62.5%;
	min-width: 300px;
	section {
		min-width: 300px;
	}
	background-color: @page-bg;
	// Hide offscreen animations on page load to prevent jumping
	overflow-x: hidden;
	*, *:before, *:after {
		box-sizing: border-box;
	}
}

body{
	font-size: 1.6rem;
	line-height: 1.5;
	color: @text;
	font-family: @main;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: @maxInner;
	width: 100%;
}

dialog {
	display: block;
	visibility: hidden;
	opacity: 0;
	margin: 0;
	padding: 0;
	transition: opacity 0.5s, visibility 0.5s;
	max-width: 100vw;
	max-height: 100vh;
	border: none;
}

dialog[open] {
	visibility: visible;
	opacity: 1;
}

[animation] {
	opacity: 0;
	animation-delay: 0.4s;
	animation-duration: 1s;
}

#rs-builder {
	[animation] {
		opacity:  1 !important;
	}
}

#cookie-box {
	z-index: 999999 !important;
}

#standard-neighborhood-map-nav {
	ul {
		margin: 0;
	}
	#current-category {
		border-radius: 0;
	}
}