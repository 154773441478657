.block.intro {
  background-color: @primary-bg;
  padding: 64px 32px;

  .title-wrapper, .text-wrapper {
    padding: 12px;
  }

  .title {
    border-left: 4px solid @secondary-bg;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .text {
    p {
      margin-bottom: 10px;
    }
  }

  .tablet({
    .inner {
      display: flex;
      flex-direction: row;
      .title-wrapper {
        width: 41.66667%;
      }
      .text-wrapper {
        flex: 58.33333%;
      }
    }
  });


}