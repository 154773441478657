.block.subpage-hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 65vh;
  height: 65dvh;
  min-height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 12px solid color-mix(in srgb, #000 32%, @secondary-bg);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }
.inner {
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 64px 10px 128px;
  .title {
    margin-bottom: 16px;
  }
}

.desktop({
  .inner {
    padding: 64px 0 128px;
  };
})

}