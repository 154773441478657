.block.site-map {
  padding: 64px 32px;
  background-repeat: repeat;

  .nav, .quick-links {
    background-color: @primary-bg;
    padding: 10px;
    margin-top: 10px;
    a, h2, h3, h4, h5 {
      color: @text-alt;
    }
    a {
      width: 100%;
    }
    a:not(.dropdown a) {
      padding: 5px 0;
      &:hover, &:focus {
        color: @text-link;
      }
    }
    .dropdown-item {
      display: flex;
      flex-direction: column;
      text-align: left;

      .dropdown-btn {
        line-height: 1.3;
        text-align: left;
        background: transparent;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: @main;
        pointer-events: none;
        cursor: default;
        opacity: 0.7;
        &:hover, &:focus {
          background: transparent;
        }
      }
      .dropdown li a {
        padding: 0 10px;
      }
      .dropdown a:hover, .dropdown a:focus {
        background-color: @text-link-hover;
      }
    }
    .dropdown {
      background-color: @secondary-bg;
      padding: 10px;
    }
  }
  .quick-links {
    animation-delay: 0.6s;
  }
}