.block.footer {
  background-color: @primary-bg;
  margin-top: 25px;
  padding: 48px 24px 96px;
  // Hides animated elements to prevent page from jumping on load
  overflow-y: hidden;

  a {
    color: @text-alt;
    display: block;
    width: max-content;
    p {
      width: max-content;
    }
    &:hover:not(.social-link, .info-link), &:focus:not(.social-link, .info-link) {
      color: @text-link;
      p, span {
        color: @text-link;
      }
    }
  }

  .info-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    box-shadow: 1px -1px 4px #2b2b2b;

    .info-link {
      border-radius: 0;
      padding: 10px 20px;
      min-width: unset;
      i {
        font-size: 20px;

      }
    }
  }

    footer {
      .top {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        .logo, .nav, .quick-links, .contact {
          padding: 12px;
        }
        .logo {
          font-size: 2rem;
          text-align: center;
          width: max-content;
          a {
            color: @heading-alt;
          }
          .name {
            border-bottom: 1px solid @heading-alt;

            font-family: @title;
            font-weight: 700;
          }
          .tagline {
            font-family: "Satisfy", cursive;
            font-weight: 400;
          }
          & a:hover, & a:focus {
            color: @text-link;
            .name {
              border-bottom: 1px solid @text-link;
            }
          }
        }
        nav {
          a {
            width: 100%;
            padding: 2px 0;
          }
          .dropdown-item {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            .dropdown-btn {
              min-width: unset;
              font-weight: 400;
              font-size: 1.6rem;
              padding: 2px 35px 2px 0;
              text-align: left;
              border-radius: unset;
              width: 100%;
              background-color: unset;
              transition: unset;
              &::after {
                content: '';
                display: block;
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: 12px;
                top: 1rem;
                // Down arrow
                border: 3px solid @text-alt;
                border-top: none;
                border-right: none;
                border-radius: 2px;
                transform: rotate(-45deg);
              }
              &:focus, &:hover {
                color: @text-link;
                &::after {
                  border: 3px solid @text-link;
                  border-top: none;
                  border-right: none;
                }
              }
            }
            .dropdown {
              background-color: @text-link;
              padding: 8px 10px;
              li {
                font-size: 1.4rem;
                padding: 2px 0;
              }
              li:hover, li:focus-within {
                background-color: @text-link-hover;
                a {
                  color: @text-alt;
                }
              }
            }
          }
        }

        .contact {
          .social {
            color: @text-alt;
            display: flex;
            flex-direction: row;
            li {
              margin-top: 15px;
              &:not(:last-of-type) {
                margin-right: 20px;
              }
            }
            .social-link {
              transition: background-color 0.3s ease;
              border-radius: 50%;
              min-width: unset;
              font-size: 18px;
              font-weight: 400;
              line-height: 1;
              width: 38px;
              height: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .bottom {
        border-top: 1px solid @heading-alt;
        padding-top: 10px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;
        .left-wrapper, .copyright, .resite {
          padding: 12px;
        }
        .left-wrapper {
          display: flex;
          flex-direction: row;
          .icons, nav ul {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          li {
            margin-left: 10px;
          }
        }
      }
    }

  .vp(1000px,{
    footer {
      .top {
        gap: 10px;
        .logo {
          font-size: 2.8rem;
        }
        .logo-wrapper, .nav, .quick-links, .contact {
          flex: 1;
        }
        nav {
          .dropdown-item {
            a, .dropdown-btn {
              width: max-content;
            }
            .dropdown {
              display: none;
            }
            &.expanded {
              .dropdown-btn {
                width: max-content;
                &:focus, &:hover, &[aria-expanded="true"] {
                  color: @text-link;
                  &::after {
                    border: 3px solid @text-link;
                    border-top: none;
                    border-right: none;
                  }
                }
              }
              .dropdown {
                display: block;
                position: absolute;
                padding: 8px 0;
                top: 100%;
                left: 0;
                width: 100%;
                z-index: 1;
                border-radius: 0 0 5px 5px;
                background-color: @page-bg;
                color: @primary-bg;
                li {
                  padding: 2px 20px;
                  a {
                    color: @primary-bg;
                  }
                }
                li:hover, li:focus-within {
                  background-color: #f5f5f5;
                }
              }

            }
          }
        }
      }

      .top, .bottom {
        flex-direction: row;
        justify-content: space-between;
      }

    }
  });
}