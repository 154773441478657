.simple-text{
	position: relative;
	padding: 45px 20px;

	h1,h2,h3,h4,h5{
		font-size: 2rem;
	}

	p{
		padding-bottom: 1.5em;
	}

	a{
		.button;
	}

.desktop({
});

	
}