@import '../base/_utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus-visible {
		outline: @button-bg auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a {
	text-decoration: none;
	outline: none;
	color: @text-link;
	cursor: pointer;
	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

.block {

}

.block {
	// headings
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		text-rendering: optimizelegibility;
		margin: 0;
		font-weight: 700;
		line-height: 1.5;
		font-family: @title;
		color: @heading;
	}
}

h1, .h1 {
	.fontSize(3.5,3.5,3.5);
}

h2, .h2 {
	.fontSize(2.4,2.4,2.4);
}

h3, .h3 {
	.fontSize(2.0,2.0,2.0);
}

h4, .h4 {
	.fontSize(2.4,2.4,2.4);
}

@media screen and (min-width: @tablet) {
	h1, .h1 {
		.fontSize(5.5,5.5,5.5);
	}
	h2, .h2 {
		.fontSize(3.2,3.2,3.2);
	}
}

button:not([id^="rs-"]), .button, a.button{
	position: relative;
	display: inline-block;
	line-height: 2;
	padding: 10px 20px;
	background: @button-bg;
	border: none;
	color: @button-text;
	font-size: 1.6rem;
	font-family: @title;
	font-weight: bold;
	border-radius: 35px;
	text-align: center;
	// margin: auto;
	min-width: 155px;
	cursor: pointer;
	transition: ease all .3s;

	@media screen and (min-width: @tablet) {
		font-size: 1.8rem;
	}

	&.ghost{
		background: none;
		color: @button-bg;
		border:0;
		border-bottom: 3px solid @button-border;
	}

	&.inline{
		display: inline-block;
	}

	&:hover, &:focus{
		background: @button-bg-hover;
	}

	&.light {
		background: @text-link;
		&:hover, &:focus{
			background: @text-link-hover;
		}
	}
}

.outline{
	background: none;
	border: 2px solid #2af0fa;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin-left: 20px;
	padding:0;

}

li {
	display: flex;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.skip-link {
	position: absolute !important;
	left: -999em;
	z-index: 999;

	a {
		color: @button-text;
    background-color: @primary-bg;
    padding: 10px 50px;
    border: 2px solid @button-text;
    text-align: center;
    font-size: 1.2em;
	}
	&:focus-within {
		left: 50%;
		transform: translateX(-50%);
	}
}

.screen-reader-text {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}

.text-alt {
	h1, h2, h3, h4, h5 {
		color: @heading-alt;
	}
	p, ul, ol, dl, li {
		color: @text-alt;
	}
}

  // Lightbox button
  .slbElement {
		font-size: 1rem;
		* {
			animation: none !important;

		}
		.slbWrap {
			cursor: pointer;
			.slbContent {
				cursor: auto;
			}
		}

		.slbArrow, .slbCloseBtn {
			position: absolute;
			border-radius: 0;
			min-width: unset;
			color: @text-alt;
			background-color: transparent;
			opacity: 0.8;
			transition: none !important;
			&:hover, &:focus {
				opacity: 1 !important;
				background-color: transparent;
			}
		}

		.slbCloseBtn {
			font-size: 2.5rem;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			&.slbCloseBtn {
				top: 15px;
			}
		}

		.slbCaption {
			padding: 0 0 20px;
			.bottom {
				display: flex;
				justify-content: space-between;
				color: @text-alt;
				.caption {
					font-size: 1.6rem !important;
				}
				.count {
					font-size: 1.2rem !important;
					opacity: 0.8;
				}
			}
		}
  }