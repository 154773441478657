.block.floorplan-single {
  padding: 80px 17px;

  .fp-details {
    padding: 0 15px;
    .image-wrapper {
      width: 100%;
      height: auto;
      padding: 0 15%;
      button {
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: unset;
      }
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .specs {
      margin-bottom: 30px;
      .title {
        padding: 20px 15px;
        h2, h3, h4, h5 {
          line-height: 1;
        }
      }
      ul {
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        li {
          padding: 8px 15px 0;
          font-size: 1.36rem;
          line-height: 2;
          color: @heading;
          opacity: 0.9;
          strong {
            margin-right: 5px;
            border-bottom: 2px solid @secondary-bg;
            text-transform: uppercase;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #cccccc;
          }
        }
      }
    }
    .description {
      background-repeat: repeat;
      margin-bottom: 30px;
      h3 {
        font-size: 1.8rem;
      }
      .descriptive-text {
        p {
          margin-top: 10px;
          color: @heading;
          opacity: 0.9;
        }
      }
    }
    .additional {
      position: relative;
      min-height: 150px;
      .fp-owl-carousel {
        // .owl-prev, .owl-next {
        //   display: none;
        // }
        display: flex;
        position: relative;
        padding: 20px 0 8px;
        margin-right: 56px;
        overflow: hidden;
        .owl-stage {
          display: flex;
        }
        .item {
          img {
            max-height: 70px;
            object-fit: contain;
            margin-bottom: 10px;
          }
          .image, .item-details {
            padding: 0 15px;
            h5 {
              font-size: 2.0rem;
            }
            span {
              font-size: 1.36rem;
              color: @primary-bg;
            }
          }
        }
        .vp(375px, {
          .item {
            display: flex;
            flex-direction: row;
          }
        });
      }
      .owl-nav {
        position: absolute;
        right: 15px;
        bottom: 50%;
        transform: translateY(50%);
        .owl-prev {
          display: none;
        }
        .owl-next {
          right: 0;
          background: transparent;
          border: 1px solid black;
          border-radius: 0;
          min-width: unset;
          padding: 1px 6px;
          i {
            color: black;
            font-size: 2rem;
          }
        }
        &.disabled {
          display: none;
        }
      }
    }
  }
  .contact-info {
    padding: 0 15px;
    .v-tour, .box {
      margin-bottom: 16px;
      width: 100%;
    }
    .v-tour {
      .vt-btn {
        display: none;
        padding: 18px 15px;
        background-color: red;
        border-radius: 0;
        width: 100%;
        min-width: unset;
        font-size: 1.6rem;
        font-weight: 400;
        transition: none;
        &:hover, &:focus {
          color: @text;
        }
      }

    }
    .box {
      padding: 18px 15px 8px;
      background-color: @primary-bg;
      a, p, label, .form-group span {
        font-size: 1.36rem;
      }
      a {
        color: @text-alt;
        &:hover, &:focus {
          color: @text-alt;
          text-decoration: underline;
        }
      }
      form {
        label {
          color: @heading-alt !important;
        }
        .form-group {
          margin-bottom: 12px;
          // In event required attribute is not available, use class .required
          &.required, &:has(input[required]) {
            input {
              border-left: 2px solid @secondary-bg;
            }
          }
        }
        input[type="submit"], input[type="button"], input[type="reset"], button {
          .button.light;
        }
        .fields {
          color: @text-alt;
        }
      }
    }
  }

  .tablet({
    .fp-details {
      .specs {
        ul {
          grid-template-columns: repeat(2, 2fr);
          li {
            &:not(:last-child) {
              border-bottom: none;
            }
            &:nth-child(odd) {
              border-right: 1px solid #cccccc;
            }
            &:nth-child(1), &:nth-child(2) {
              border-bottom: 1px solid #cccccc;
            }
          }
        }
      }
    }
  });

  .vp(1000px, {
    .inner {
      display: flex;
      flex-direction: row;
    }
    .fp-details {
      width: 75%;
      .specs {
        ul {
          display: flex;
          flex-direction: row;
          li {
            flex: 1;
            border: none !important;
            display: flex;
            flex-direction: column;
            padding: 8px 0 0;
            font-size: 1.6rem;
            strong {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .contact-info {
      width: 25%;
    }
  });
}