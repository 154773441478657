.block.gallery {
  padding: 80px 32px;

  .gallery-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    .gallery-item {
      overflow: hidden;
      position: relative;
      aspect-ratio: 1 / 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
        object-position: center center;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        pointer-events: none;
      }
      &:hover, &:focus {
        &::after {
          opacity: 1;
        }
        img {
          transform: scale(1.3);
        }
      }
    }
  }

  .vp(480px, {
    .gallery-container {
      grid-template-columns: repeat(2, 1fr);
    }
  });

  .tablet({
    .gallery-container {
      grid-template-columns: repeat(4, 1fr);
    }
  });
}