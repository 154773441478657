.block.testimonials {
  padding: 64px 32px;
  background-color: @primary-bg;

  .title {
    border-left: 4px solid @secondary-bg;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .testimonial-slides {
    position: relative;
    display: flex;
    .swiper {
      padding-bottom: 50px;
    }
    .swiper-slide {
      height: 100%;
      .text {
        margin-top: 10px;
        pointer-events: none;
        color: @text-alt;
      }
    }
    .nav-buttons {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0px;
      flex-direction: row;
      gap: 40px;
      margin-bottom: 5px;
    }
    .nav-arrow {
      width: 60px;
      position: relative !important;
      padding: 6px 8px;
      &::after {
        display: none;
      }
      transition: background-color 0.3s ease;
        &:hover, &:focus {
          background-color: @secondary-bg;
        }
      .arrow {
        height: auto;
        max-width: 100%;
        user-select: none;

      }
      .prev {
        transform: rotate(180deg);
      }
    }
    .swiper-pagination {
      pointer-events: none;
      user-select: none;
      display: none;
      position: absolute;
      bottom: 35px;
      width: 100%;
      justify-content: center;
      .swiper-pagination-bullet {
        background-color: @page-bg;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background-color: @secondary-bg;
        }
      }
    }
    .swiper-scrollbar {
      bottom: 30px;
      position: absolute;
      .swiper-scrollbar-drag {
        background-color: @secondary-bg;
      }
    }
  }

  .tablet({
    .testimonial-slides {
      .nav-buttons {
        display: flex;
      }
      .swiper-pagination {
        display: flex;
      }
      .swiper-scrollbar {
        display: none;
      }
    }
  })
}