form{
	margin: auto;
	// max-width: 780px;

	fieldset {
		border: 0;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		legend {
			padding: 15px 0 10px;
			font-size: 20px;
			font-family: @title;
			color: @heading;
			text-transform: capitalize;
		}
	}




	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="phone"],
	select,
	textarea {
		box-sizing: border-box;
		padding: 0.667rem 1.333rem;
		border:0;
		text-indent: 0;
		border-radius: 0;
		width: 100%;
		min-height: 37px;
		line-height: 1;
		background-color: #f4f4f4;
		color: @text;
		border: 1px solid #ddd;
		font-size: 1.3rem;
		font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

		&:focus, &:active {
		}

		&[disabled] {
			&:focus, &:active {
			}
		}
	}

	select {

	}

	textarea {
		box-sizing: border-box;
		min-height: 120px;
		width: 100%;
		padding: 6px 13px 32px;
		border:0;
		text-indent: 0px;
		// border-radius: 5px;
		background-color: #f4f4f4;
		border: 1px solid #ddd;
		font-family: @main;
		box-sizing: border-box;
		resize: vertical;
		max-height: 500px;
		overflow-y: auto;
	}

	.checkbox-options, .radio-options {
		display: flex;
		flex-direction: column;
		width: max-content;
	}

	input[type="checkbox"] {

	}

	input[type="radio"] {
		margin-right: 8px;
	}

	input[type="submit"], input[type="button"], input[type="reset"], button {
		.button;
		border:0;
		margin: 25px 0 20px;
	}

	input[type="color"] {

	}

	input[type="file"] {

	}

	input[type="range"] {

	}

	input[type="password"] {
		letter-spacing: 0.3em;
	}

	::-webkit-input-placeholder {
		//color: #fff;
	}

	::-moz-placeholder {
		//color: #fff;
	}

	:-ms-input-placeholder {
		//color: #fff;
	}

	:-moz-placeholder {
		//color: #fff;
	}
	label {
		color: @button-bg;
	}

	.block label{
		font-weight: 400;
		font-size: 1.36rem;
	}

	.field-wrapper{
		padding-bottom: 25px;
	}

	.form-group {
		// In event required attribute is not available, use class .required
		&.required, &:has(input[required]), &:has(input[required="required"]) {
			> label {
				&:after {
					content: "*";
					color: @secondary-bg;
					font-size: 1.13rem;
					font-weight: 400;
					vertical-align: super;
				}
			}
		}
	}
}




@media screen and (min-width: 768px){
	.fields, reform, fieldset{
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
	}

	.iwrap, .form-group, .field-wrap {
		width: 100%;

		&.quarter{
			width: 23%;
		}

		&.half{
			width: 48%;
		}

		&.third{
			width: 73%;
		}

		&.one-third{
			width: 31%;
		}

		&.two-thirds{
			width: 65%;
		}

		&.textarea, &.full{
			width: 100%;
		}
	}
}