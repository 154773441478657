.block.corporate {
  padding: 64px 34px;
  min-height: 600px;
  position: relative;
  background-repeat: repeat;
  background-color: @page-bg;

  .bg-colors {
    background: linear-gradient(to bottom, transparent 350px, @page-bg 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .image-wrapper {
    height: 320px;
    width: 100%;
    padding-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inner {
    position: relative;
  }

  .content {
    padding: 12px 0 0 44px;
    .title {
      padding-left: 10px;
      margin-bottom: 20px;
      border-left: 4px solid @secondary-bg;
    }
    .text {
      padding-top: 10px;
      p {
        margin-bottom: 10px;
      }
    }
    .button-wrapper {
      margin-top: 25px;
    }
  }

  .tablet({
    .inner {
      display: flex;
      flex-direction: row;
      gap: 64px;
      .image-wrapper, .content {
        padding: 0;
        flex: 1;
        height: 650px;
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  });
}