article.text-content, .block.resident-services-text, .resident-services-forms {
  padding: 80px 32px;

  li {
    &::marker {
      color: @button-bg;
    }
  }
  p, li {
    color: @button-bg;
  }

  p {
    margin-top: 20px;
  }

  ul, ol {
    margin-top: 10px;
  }

  li {
    display: list-item;
  }

  a {

    &:hover, &:focus {
      text-decoration: underline;
      color: @text-link-hover;
    }
  }

  h2 {
    padding-left: 10px;
    border-left: 4px solid @secondary-bg;
  }
  h3 {
    font-size: 2.0rem;
  }
  h4 {
    font-size: 2.0rem;
  }
  h5 {
    font-size: 1.8rem;
  }

  .tablet({
    h3, h4 {
      font-size: 2.2rem;
    }
    h5 {
      font-size: 2.0rem;
    }
  });
}

body:has(article.text-content) {
  .block.subpage-hero {
    .inner {
      padding: 64px 0 0;
      margin: 0 32px;
    }
  }
}