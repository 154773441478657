.block.neighborhood-poi {
  background-color: @primary-bg;
  background-repeat: repeat;
  padding: 52px 20px;
  position: relative;

  .poi-card {
    width: calc(100% - 24px);
    margin: 12px;
    background-color: @button-bg-hover;
    .poi-image {
      height: 320px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      padding: 16px;
      animation-delay: 0.6s;
      .body {
        a {
          color: @text-alt;
          text-decoration: underline;
          &:hover, &:focus {
            color: @heading;
          }
        }
      }
    }
    &:hover, &:focus, &:focus-within {
        background-color: @secondary-bg;
    }
  }

  .tablet({
    .poi-container {
      display: flex;
      flex-wrap: wrap;
    }
    .poi-card {
      flex: 0 0 calc(50% - 24px);
    }
  });



}