.block.contact {
  position: relative;
  padding: 52px 17px;
  background-repeat: repeat;
  background-color: @page-bg;

  .bg-colors {
    background: linear-gradient(to bottom, @page-bg 650px, transparent 650px);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  article {
    width: 100%;
  }
  .details {
    padding: 12px;
    .detail-box {
      padding: 32px;
      background-color: @primary-bg;
      &:not(:first-of-type) {
        margin-top: 10px;
        animation-delay: 0.5s;
      }
      .address {
        margin-bottom: 10px;
        width: 220px;
      }
      a {
        color: @text-alt;
        &:hover, &:focus {
          color: @text-link;
        }
      }
      .socials {
        .socials-list {
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          li {
            &:not(:last-of-type) {
              margin-right: 10px;
            }
            a {
              i {
                transition: background-color 0.3s ease;
                background-color: @secondary-bg;
                border-radius: 50%;
                min-width: unset;
                font-size: 18px;
                font-weight: 400;
                line-height: 1;
                width: 38px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &:hover, &:focus {
                i {
                  background-color: @text-link;
                  color: initial;
                }
              }
            }
          }
        }
      }
      .content {
        * {
          color: @text-alt;
        }
      }
    }
    .map-container {
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #eeeeee;
      width: 100%;
      padding: 0;
      animation-delay: 0.6s;
      #contact-map {
        height: 320px;
        button {
          min-width: unset;
          border-radius: 0;
        }
      }
    }
  }

  .form {
    padding: 12px;
    form {
      .form-group {
        margin-bottom: 12px;
        // In event required attribute is not available, use class .required
        &.required, &:has(input[required]) {
          input {
            border-left: 2px solid @secondary-bg;
          }
        }
      }
    }
  }


  .tablet({
    .inner {
      display: flex;
      flex-direction: row;
    }
    .details {
      width: 33.3333%;
      #contact-map {
        height: 400px;
      }
    }
    .form {
      width: 66.6667%;
    }
  });
}


