.block.hero {
  min-height: 500px;
  position: relative;
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: flex-end;
  border-bottom: 12px solid @secondary-bg;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    .swiper {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    iframe {
      height: 56.25vw;
      left: 50%;
      min-height: calc(100% + 100px);
      min-width: calc(100% + 100px);
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.77777778vh;
    }
  }

  .inner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 auto;
    z-index: 1;
  }
  .content {
    width: 100%;
    padding: 64px 10px 128px;
    .text {
      width: max-content;
      max-width: 500px;
      * {
        line-height: 1;
      }
      .tagline {
        margin-bottom: 15px;
        p {
          font-size: 3rem;
          font-style: italic;
        }
      }
      .title {
        margin-bottom:10px;
      }
    }
    .buttons {
      a {
        margin-top: 35px;
      }
      a:nth-child(odd) {
        .button;
      }
      a:nth-child(even) {
        .button;
        .button.light;
      }
    }
  }

  .desktop({
    .content {
      padding: 64px 0 128px;
    }
  });


  &.editing {

  }
}