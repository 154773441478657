.block.cta-right {
  min-height: 500px;
  position: relative;
  padding-bottom: 200px;

  img.background {
    position: absolute;
    bottom: 0;
    left: 0;
    object-position: top left;
    object-fit: cover;
    width: 100%;
    height: 275px;
  }

  .content-container {
    width: 100%;
    max-width: 800px;
    position: relative;
    margin-left: auto;
    .content {
      width: 100%;
      background-color: @primary-bg;
      padding: 32px;
      position: relative;
    }
    .square {
      position: absolute;
      left: -30px;
      bottom: -30px;
      width: 220px;
      height: 200px;
      background-color: @secondary-bg;
    }
  }

  .text {
    p {
      margin-bottom: 10px;
    }
  }

  .title {
    border-left: 4px solid @secondary-bg;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .button-wrapper {
    margin-top: 35px;
  }

  .tablet({
    padding-bottom: 0;
    img.background {
      position: absolute;
      top: 0;
      left: 0;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .content {
      padding: 64px;
    }
  })
}