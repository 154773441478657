.block.features-amenities {
  background-color: @primary-bg;
  background-repeat: repeat;
  padding: 92px 20px;
  position: relative;
  .bg-colors {
    background: linear-gradient(to bottom, transparent 300px, @primary-bg 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .inner {
    display: flex;
    flex-direction: column;
  }
  &.light .inner {
    flex-direction: column-reverse;
  }
  .image-container {
    padding: 12px;
    height: 320px;
    width: 100%;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: @secondary-bg;
      width: 200px;
      max-width: 100%;
      height: 220px;
      z-index: -1;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .details {
    padding: 12px;
    .title {
      padding-left: 10px;
      border-left: 4px solid @secondary-bg;
      margin-bottom: 20px;
    }
    ul {
      list-style: disc;
      padding-left: 30px;
      li {
        display: list-item;
      }
    }
  }

  .shoppes-card {
    padding: 12px;
    img {
      height: 320px;
      width: 100%;
      object-fit: cover;
    }
    .shoppes-details {
      padding: 16px;
      background-color: @button-bg-hover;
      &:hover, &:focus-within {
        background-color: @secondary-bg;
      }
      a {
        color: @text-alt;
        text-decoration: underline;
        &:hover, &:focus {
          color: @primary-bg;
        }
      }
      li {
        margin: 15px 0;
      }
    }
  }

  &.light {
    background-color: @page-bg;
    .bg-colors {
      background: linear-gradient(to bottom, transparent 300px,  @page-bg 0);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    .inner {
      h2, h3, h4, h5 {
        color: @heading;
      }
      p, li {
        color: @primary-bg;
      }
      .image-container {
        &::before {
          left: 0;
          background-color: @primary-bg;
        }
      }
    }
  }

  .tablet({
    padding: 70px 20px;
    .inner {
      display: flex;
      flex-direction: row;
      gap: 25px;
      .image-container, .details, .shoppes-card {
        flex: 1;
        padding: 32px;
      }
      &:has(.shoppes-card) {
        .details {
          padding: 32px 0;
        }
        .shoppes-card {
          padding: 32px 0;
        }
      }
      &:not(:has(.shoppes-card)) {
        .details {
          padding: 32px 0 32px 32px;
        }
      }

      .image-container {
        height: 600px;
        padding: 32px 32px 32px 0;
        &::before {
          width: 300px;
          height: 420px;
          right: 0;
          bottom: 0;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }
    }
    &.light {
      .inner {
        flex-direction: row-reverse;
        .image-container {
          padding: 32px 0 32px 32px;
          &::before {
            left: 0;
          }
        }
        &:has(.shoppes-card) {
          .details {
            padding: 32px;
          }
          .shoppes-card {
            padding: 32px 32px 32px 0;
          }
        }
        &:not(:has(.shoppes-card)) {
          .details {
            padding: 32px 32px 32px 0;
          }
        }
      }
    }
  });
}