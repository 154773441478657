.block.outro {

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title-container {
    width: 100%;
    background-color: @secondary-bg;
    padding: 32px;
    text-align: center
  }

  .link-container {
    width: 95%;
    animation-delay: 0.5s;
    a {
      font-size: 2.4rem;
      background-color: @primary-bg;
      width: 100%;
      padding: 32px;
      color: @heading-alt;
      font-family: @title;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: background-color 0.2s ease;
      h2, h3, h4, h5, img {
        display: inline-block;
      }
      img {
        height: 20px;
        width: 60px;
        margin-left: 10px;
        transition: margin-left 0.2s ease;
      }
      &:hover {
        background-color: @button-bg-hover;
        img {
          margin-left: 20px;
        }
      }
      &:hover, &:focus {
        background-color: @button-bg-hover;
        img {
          margin-left: 20px;
        }
      }
    }
  }

  .tablet({
    .link-container {
      width: 65%;
      a {
        font-size: 3.2rem;
      }
    }
  });
}