.block.specials-flag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;

  .flag {
    position: absolute;
    top: 340px;
    right: 12px;
    z-index: 2;
    transform: rotate(-90deg);
    transform-origin: top;
    button {
      background-color: #da4835;
      color: #fff;
      border-radius: 5px 5px 0 0;
      font-size: 2.4rem;
      padding: 10px;
      min-width: unset;
      &:hover, &:focus {
        background-color: #ba3422;
      }
    }
  }

  .specials-overlay {
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    z-index: 999;
    height: 100vh;
    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease;
    &[open] {
      visibility: visible;
      opacity: 1;
    }
    .backdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.86);
      cursor: zoom-out;
    }
    .close {
      width: 100%;
      padding: 10px;
      top: 0;
      position: absolute;
      display: flex;
      justify-content: flex-end;
      z-index: 1;
      button {
        font-size: 2rem;
        color: @heading-alt;
        cursor: pointer;
        width: max-content;
        height: max-content;
        min-width: unset;
        border-radius: 0;
        background-color: transparent;
        text-shadow: black 0px 0px 1px;
      }
    }
      .content {
        background-color: @page-bg;
        padding: 32px;
        max-height: calc(100vh - 160px);
        overflow: auto;
        position: relative;
        width: 100%;
        .title {
          padding-left: 10px;
          border-left: 4px solid @heading-link;
          margin-bottom: 20px;
        }
        .text {
          p {
            margin-bottom: 10px;
          }
        }
      }
  }

  .tablet({
    .specials-overlay {
        .content {
          width: 640px;
          margin: 0 auto;
        }
    }
  });
}


#specials {
  display: none !important;
}